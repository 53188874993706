@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Raleway:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=Inter:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Source+Sans+3:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Outfit:wght@300;400;600&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Arial, sans-serif; */
  /* font-family: 'Raleway', sans-serif; */
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Source Sans 3', sans-serif; 
  /* font-family: 'Outfit', sans-serif; */
}

body {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #0A0A23;
  /* background-image: url('/public/images/background-07.webp'); */
  /* background-size: cover; */
  /* background-position: center; */
  /* overflow-x: hidden; */
}

/* Add a new wrapper div for the background */
.background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('/public/images/background-07.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* This makes it stay fixed while scrolling */
  z-index: 1;
}

/* Container */
.container {
  position: relative;
  max-width: 800px;
  padding: 20px;
  text-align: center;
  margin: 20vh auto;
  z-index: 2;
  background: transparent;
}

/* Overlay */
.container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.main-title,
.subtitle,
.tagline,
.section-title,
.text-block {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Title */
.main-title {
  font-size: 2.7rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  /* font-family: 'Vollkorn SC', serif; */
  /* font-family: 'Cinzel', serif; */
  /* font-family: 'Cormorant Garamond', serif; */
  /* font-family: 'Marcellus', serif; */
  font-family: 'Gilda Display', serif;
}

.tagline {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #ffffff;
}

/* Feature Section */
.feature-section {
  margin: 10vh auto;
  text-align: center;
  max-width: 800px;
}

.section-title {
  font-size: 1.4rem;
  /* margin-bottom: 2rem; */
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
}

/* Feature Cards */
/* .feature-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 2rem 0;
  max-width: 800px;
  margin: 2rem auto;
  margin-top: 10vh;
} */
.feature-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.feature-card {
  background: rgba(191, 117, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 1.2rem;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  transition: transform 0.3s ease;
  width: 180px;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-emoji {
  font-size: 2rem;
  display: block;
  margin-bottom: 0.5rem;
}

.feature-card p {
  font-size: 0.9rem;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Steps Section */
.steps-section {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem 0;
  margin-top: 15vh;
  flex-wrap: wrap;
}

.step-card {
  background: rgba(0, 180, 216, 0.1);
  backdrop-filter: blur(5px);
  padding: 1rem;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  min-width: 150px;
}

.step-number {
  /* background: #00B4D8; */
  background: #BF75FF;
  color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.step-card p {
  margin: 0;
  font-size: 0.9rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* CTA Button */
.cta-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #F5F5F5;
  background-color: #BF75FF;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background-color: #00B4D8;
  transform: scale(1.05);
}

/* Text Block */
.text-block {
  font-size: 1.2rem;
  max-width: 400px;
  margin: 2rem auto;
  margin-top: 10vh;
  text-align: center;
  color: #ffffff;
}

/* Contact Block */
.contact-block {
  margin-top: 2vh;
  font-size: 1rem;
  color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 20px;
}

.contact-block h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.email-link {
  font-size: 1.1rem;
  /* color: #00B4D8; */
  color: #BF75FF;
  text-decoration: none;
}

.email-link:hover {
  color: #00B4D8;
  /* text-decoration: underline; */
}

.secondary-cta {
  display: inline-block;
  padding: 12px 25px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #BF75FF;
  background-color: transparent;
  border: 2px solid #BF75FF;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.secondary-cta:hover {
  background-color: rgba(191, 117, 255, 0.1);
  color: #00B4D8;
  border-color: #00B4D8;
  transform: scale(1.05);
}

/* Media Queries for Tablets */
@media (max-width: 768px) {
  .container {
    margin-top: 20vh;
    padding: 15px;
    max-width: 90%;
    /* background-color: black; */
  }

  .main-title {
    font-size: 2.4rem;
    white-space: normal;
  }

  .tagline {
    font-size: 1.4rem;
    margin-top: 5vh;
  }

  .cta-button {
    margin-top: 2vh;
  }

  .text-block {
    max-width: 70%;
    margin-top: 12vh;
    font-size: 1.2rem;
  }

  .steps-section {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 12vh;
  }

  .step-card {
    width: 80%;
    max-width: 300px;
    justify-content: center;
  }

  /* .feature-cards {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  } */
  .feature-section {
    margin-top: 12vh;
  }
  
  .feature-cards {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .feature-card {
    width: 80%;
    max-width: 300px;
  }

  .contact-block {
    margin-top: 12vh;
    max-width: 70%;
    /* background-color: black; */
  }

  .secondary-cta {
    margin-top: 2.5vh;
  }
}

/* Media Queries for Mobile */
@media (max-width: 480px) {
  body {
    background-position: center center;
  }

  .container {
    margin-top: 10vh;
    padding: 10px;
  }

  .main-title {
    font-size: 2.2rem;
  }

  .tagline {
    font-size: 1.2rem;
  }

  .feature-card, .step-card {
    width: 90%;
    padding: 1rem;
  }

  .feature-emoji {
    font-size: 1.5rem;
  }

  .feature-card p, .step-card p {
    font-size: 0.8rem;
  }

  .cta-button {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .text-block {
    font-size: 1.0rem;
    margin: 1.5rem auto;
  }

  .secondary-cta {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

/* Media Query for very small screens */
/* @media (max-width: 320px) {

  .main-title {
    font-size: 1.4rem;
  }

  .tagline {
    font-size: 1rem;
  }
} */